import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import numeral from 'numeral';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Components
import GroupCard from '../../components/GroupCard';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
// import infoModal from '../../assets/info-modal-1.png';

NoDataToDisplay(Highcharts);

class DebriefAccuracy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      popup: false,
      filter_amount: null,
      filter_accuracy: null,
    };

    // Bindings
    this.selectAmount = this.selectAmount.bind(this);

    // Refs
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  selectAmount = (amount, accuracy) => {
    this.setState({ filter_amount: amount, filter_accuracy: accuracy }, () => {
      // Reflow the chart
      this.chartRef.current.chart.reflow();
    });
  };

  render() {
    // const { popup } = this.state;
    const { filter_amount, filter_accuracy } = this.state;
    const { game } = this.props;
    const gameData = game.data;

    // Let's create an array representing 50 applications
    // We'll use this array as the x-axis for the chart
    // const totalApplications = Array.from(Array(50).keys());

    // Count the number of processed applications per group
    // A processed application is one that has a decision
    // const groupsProcessedApplications = gameData?.groups?.map((g) => {
    //   const processed = g?.items?.find((i) => i.activity === 'decision');
    //   return processed?.length || 0;
    // });

    // For every group, count the number of correct decisions
    // const groupsCorrectDecisions = gameData?.groups?.map((g) => {
    //   const correct = g?.items?.filter((i) => i.performance === 100);
    //   return correct?.length || 0;
    // });

    // For every group calculate the number of decisions made and their performance
    const groupsAccuracy = gameData?.groups?.map((g, gi) => {
      const groupIdentifier = g?.name || `Team ${gi + 1}`;
      const decisions = g?.items?.filter((i) => i.activity === 'decision');
      const correct = decisions?.filter((i) => i.performance === 100);
      const accuracy = (correct?.length / decisions?.length) * 100;
      return { x: decisions?.length, y: accuracy, name: groupIdentifier };
    });

    // Filter groups by their number of processed applications (x-axis)
    // use the filter_amount state variable to filter the groups
    const filteredGroups = gameData?.groups?.filter((g) => {
      const decisions = g?.items?.filter((i) => i.activity === 'decision');
      const correct = decisions?.filter((i) => i.performance === 100);
      const accuracy = (correct?.length / decisions?.length) * 100;
      return (
        decisions?.length === filter_amount && accuracy === filter_accuracy
      );
    });

    // TO DISCUSS: If we only consider the latest responses, and all the responses were submitted by the same author
    // does it count as collaboration?

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title fs-3'>
          Accuracy{' '}
          {/* <FontAwesomeIcon
            className='ms-1 text-secondary'
            style={{ fontSize: '1.25rem', cursor: 'pointer' }}
            icon='question-circle'
            onClick={() => this.setState({ popup: !popup })}
          /> */}{' '}
          <small className='float-end'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row mt-3'>
          <div className='col-12'>
            <p className='text-center'>
              Each data point represents a team's count of completed
              applications versus its decision accuracy. Click on a data point
              to display corresponding teams and their performance details.
            </p>
          </div>
          <div className={`${filter_amount ? 'col-7' : 'col-12'}`}>
            <HighchartsReact
              ref={this.chartRef}
              highcharts={Highcharts}
              options={{
                title: { text: '' },
                chart: { type: 'scatter', height: '500px' },
                credits: { enabled: false },
                legend: { enabled: false },
                xAxis: {
                  title: { text: 'Completed Loan Applications' },
                  min: 0,
                  minRange: 10,
                  allowDecimals: false,
                },
                yAxis: {
                  labels: { style: { fontSize: '14px' } },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  title: {
                    text: 'Accuracy',
                  },
                },
                lang: {
                  noData: 'Pending results',
                },
                tooltip: {
                  formatter: function () {
                    return (
                      `Team: ${this?.point?.name}<br>` +
                      `Completed Loan Applications: <b>${this.x}</b><br>` +
                      `Accuracy: <b>${numeral(this.y).format(
                        '0.0'
                      )}%</b><br/> <br/>` +
                      `Click for additional details.`
                    );
                  },
                },
                plotOptions: {
                  series: {
                    cursor: 'pointer',
                    point: {
                      events: {
                        click: (e) => {
                          this.selectAmount(e.point.x, e.point.y);
                        },
                      },
                    },
                    // borderWidth: 5,
                    // borderColor: 'black',
                  },
                },
                series: [
                  {
                    name: 'Incorrect',
                    // color: 'rgba(223, 83, 83, 1)',
                    // color: 'rgba(223, 83, 83, 1)',
                    data: groupsAccuracy,
                  },
                ],
              }}
            />
          </div>
          <div className={`${filter_amount ? 'col-5' : 'd-none'}`}>
            <button
              className='btn btn-sm btn-link rounded-pill ps-0 mt-0 pt-0 mb-2'
              onClick={() => {
                this.setState({ filter_amount: null }, () => {
                  // Reflow the chart
                  this.chartRef.current.chart.reflow();
                });
              }}>
              <small>Close Details</small>
            </button>
            {filteredGroups?.map((g, gi) => (
              <GroupCard key={`group-${g?._id}`} group={g} index={gi} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(DebriefAccuracy);
