import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import numeral from 'numeral';
import { CSVLink } from 'react-csv';
// import { Tooltip } from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// Actions
import { gameActions } from '../../actions';

// import { Modal, ModalBody } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list';
// import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: null,
      tip: false,
    };

    // Bindings
    this.rowClick = this.rowClick.bind(this);
    this.toggleTip = this.toggleTip.bind(this);
    this.getItemAuthor = this.getItemAuthor.bind(this);
    this.formatElapsed = this.formatElapsed.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      dispatch(gameActions.fetchGames(match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  rowClick(data) {
    this.setState({ group: data });
  }

  toggleTip() {
    this.setState({ tip: !this.state.tip });
  }

  getItemAuthor(item) {
    const { game } = this.props;
    const { players } = game.data;
    const author = players.find((p) => p._id === item?.author);
    return author;
  }

  formatElapsed(elapsed) {
    if (typeof elapsed === 'undefined') return undefined;
    return {
      value: elapsed,
      label: numeral(elapsed).format('00:00:00'),
    };
  }

  render() {
    const { game } = this.props;

    const groups = game.data.groups || [];

    // Groups
    const groupsList = groups.map((g, gi) => {
      // Group position (index+1)
      const groupPosition = gi + 1;
      const groupName = g?.name || `Group ${groupPosition}`;

      // Group size (number of players in the group)
      const groupSize = g.players?.length || 0;

      // Remove duplicate items from the group
      // use the type and application properties to identify the items
      // in case of duplicates, keep the most recent value for created_at property
      const groupUniqueItems = g?.items?.reduce((acc, item) => {
        const { activity, application } = item;
        const existingItem = acc.find(
          (i) => i.activity === activity && i.application === application
        );
        if (existingItem) {
          // If the item already exists, check if the current item is more recent
          if (new Date(item.created_at) > new Date(existingItem.created_at)) {
            // If the current item is more recent, replace the existing item
            acc.splice(acc.indexOf(existingItem), 1, item);
          }
        } else {
          // If the item doesn't exist, add it to the accumulator
          acc.push(item);
        }
        return acc;
      }, []);

      // Calculate stats for the group
      const uniqueApplications = [
        ...new Set(g?.items?.map((i) => i.application)),
      ];
      // Get the unique applications that don't have a decision
      const initialized = uniqueApplications?.filter((a) => {
        const hasDecision = g?.items?.find(
          (i) => i.application === a && i.activity === 'decision'
        );
        return !hasDecision;
      });

      const completed = groupUniqueItems?.filter(
        (i) => i.activity === 'decision'
      );
      // WIP is the number of initialized items minus the number of completed items
      const wip = initialized?.length;
      const inventory = 49 - completed?.length - wip;

      const correct = completed?.filter((i) => i.performance === 100);
      const accuracy = correct?.length / completed?.length;

      // Count how many players worked on the same item
      // NOTE: If we use groupUniqueItems, then we're removing the re-worked items
      const collaborationItems = uniqueApplications?.map((i) => {
        const workers = g?.items
          ?.filter((j) => j?.application === i && j?._id !== i?._id)
          .map((j) => j?.author);
        const uniqueAuthors = [...new Set(workers)];
        return uniqueAuthors ? uniqueAuthors?.length : 0;
      });
      // Let's calculate the statistics mode for the collaboration items
      const collaborationModeMap = collaborationItems?.reduce((acc, item) => {
        if (item) {
          acc[item] = typeof acc[item] !== 'undefined' ? acc[item] + 1 : 1;
        }
        return acc;
      }, {});
      const collaborationMode = Object.keys(collaborationModeMap).reduce(
        (a, b) => (collaborationModeMap[a] > collaborationModeMap[b] ? a : b),
        []
      );
      // console.log({
      //   collaborationItems,
      //   collaborationModeMap,
      //   collaborationMode,
      // });

      // Calculate the avg performance
      // items' performance is binary: 0 or 100
      // we'll count the number of 100s and divide by the total number of items
      const correctItems = groupUniqueItems.filter(
        (item) => item?.performance === 100
      );
      const task_quality =
        groupUniqueItems.length > 0
          ? correctItems?.length / groupUniqueItems.length
          : 0;
      // console.log({ task_quality });

      // Loans & Tasks details
      // Loop the applications and each of the activities
      const game_applications = game?.data?.applications?.reduce(
        (acc, application_data) => {
          // Initialize the application elapsed time
          let application_elapsed_time = 0;

          // Let's sort the groupUniqueItems by created_at
          // from oldest to newest
          const sortedItems = groupUniqueItems?.sort((a, b) => {
            return new Date(a.created_at) - new Date(b.created_at);
          });

          // Get the first item (response) for the current application
          // use the created_at property to identify the first item
          const firstItem = sortedItems?.find(
            (i) => i.application === application_data?.id
          );

          // Get the last item (response) for the current application
          // use the created_at property to identify the last item
          const lastItem = sortedItems?.find(
            (i) =>
              i.application === application_data?.id &&
              i.activity === 'decision'
          );

          // Loop the activities
          const activities = application_data?.activities?.map(
            (activity_data) => {
              // Find the response for the current activity and application
              const activity_response = groupUniqueItems?.find(
                (i) =>
                  i.activity === activity_data?.type &&
                  i.application === application_data?.id
              );

              // Add the elapsed time to the application elapsed time
              application_elapsed_time += activity_response?.elapsed_time || 0;

              // Get the activity type
              const activity_type = activity_response?.activity;

              // Get the response author
              const activity_author =
                this.getItemAuthor(activity_response)?.email;

              // Get the response creation time
              const activity_created_at = activity_response?.created_at
                ? new Date(activity_response?.created_at).toLocaleString()
                : '';
              // Get the response elapsed time

              const activity_elapsed_time = this.formatElapsed(
                activity_response?.elapsed_time / 1000 || undefined
              );

              // Get the response performance
              const activity_performance = activity_response?.performance;

              // Return the activity details
              // Replace "-" and " " with "_" in the activity type
              const activity_label = activity_data?.type?.replace(/-| /g, '_');
              return {
                [`loan_${application_data?.id}_${activity_label}_type`]:
                  activity_type,
                [`loan_${application_data?.id}_${activity_label}_author`]:
                  activity_author,
                [`loan_${application_data?.id}_${activity_label}_created_at`]:
                  activity_created_at,
                [`loan_${application_data?.id}_${activity_label}_elapsed_time`]:
                  activity_elapsed_time,
                [`loan_${application_data?.id}_${activity_label}_performance`]:
                  activity_performance,
              };
            }
          );

          // Convert the activities array to a json object
          const activities_json = activities?.reduce((acc, activity) => {
            acc = { ...acc, ...activity };
            return acc;
          }, {});

          // Add all the activities properties to the accumulator
          acc = {
            ...acc,
            ...activities_json,
            // Total elapsed time for the application
            [`loan_${application_data?.id}_elapsed_total`]: this.formatElapsed(
              application_elapsed_time / 1000
            ),
            // First item (response) time
            [`loan_${application_data?.id}_first_item_created_at`]:
              firstItem?.created_at
                ? new Date(firstItem?.created_at).toLocaleString()
                : '',
            // Last item (response) time
            [`loan_${application_data?.id}_last_item_created_at`]:
              lastItem?.created_at
                ? new Date(lastItem?.created_at).toLocaleString()
                : '',
          };

          // Return the application details
          return acc;
        },
        {}
      );

      // // Tasks duration
      // const firstFrontRatio = groupUniqueItems?.find(
      //   (i) => i.activity === 'front-ratio'
      // );
      // const lastFrontRatio = groupUniqueItems?.findLast(
      //   (i) => i.activity === 'front-ratio'
      // );
      // const firstBackRatio = groupUniqueItems?.find(
      //   (i) => i.activity === 'back-ratio'
      // );
      // const lastBackRatio = groupUniqueItems?.findLast(
      //   (i) => i.activity === 'back-ratio'
      // );
      // const firstLoanToValue = groupUniqueItems?.find(
      //   (i) => i.activity === 'loan-to-value'
      // );
      // const lastLoanToValue = groupUniqueItems?.findLast(
      //   (i) => i.activity === 'loan-to-value'
      // );
      // const firstRiskAssessment = groupUniqueItems?.find(
      //   (i) => i.activity === 'risk-assessment'
      // );
      // const lastRiskAssessment = groupUniqueItems?.findLast(
      //   (i) => i.activity === 'risk-assessment'
      // );
      // const firstDecision = groupUniqueItems?.find(
      //   (i) => i.activity === 'decision'
      // );
      // const lastDecision = groupUniqueItems?.findLast(
      //   (i) => i.activity === 'decision'
      // );

      // // Tasks elapsed time
      // const firstFrontRatioElapsedTime =
      //   firstFrontRatio?.elapsed_time / 1000 || 0;
      // const lastFrontRatioElapsedTime =
      //   lastFrontRatio?.elapsed_time / 1000 || 0;
      // const firstBackRatioElapsedTime =
      //   firstBackRatio?.elapsed_time / 1000 || 0;
      // const lastBackRatioElapsedTime = lastBackRatio?.elapsed_time / 1000 || 0;
      // const firstLoanToValueElapsedTime =
      //   firstLoanToValue?.elapsed_time / 1000 || 0;
      // const lastLoanToValueElapsedTime =
      //   lastLoanToValue?.elapsed_time / 1000 || 0;
      // const firstRiskAssessmentElapsedTime =
      //   firstRiskAssessment?.elapsed_time / 1000 || 0;
      // const lastRiskAssessmentElapsedTime =
      //   lastRiskAssessment?.elapsed_time / 1000 || 0;
      // const firstDecisionElapsedTime = firstDecision?.elapsed_time / 1000 || 0;
      // const lastDecisionElapsedTime = lastDecision?.elapsed_time / 1000 || 0;

      // // Find the first decision item
      // const firstApplicationDecision = groupUniqueItems?.find(
      //   (i) => i.activity === 'decision'
      // );
      // const firstApplicationItems = groupUniqueItems?.filter(
      //   (i) => i.application === firstApplicationDecision?.application
      // );
      // const firstApplicationElapsedTime = firstApplicationItems?.reduce(
      //   (acc, item) => {
      //     return acc + (item.elapsed_time / 1000 || 0);
      //   },
      //   0
      // );

      // // Find the last decision item
      // const lastApplicationDecision = groupUniqueItems?.findLast(
      //   (i) => i.activity === 'decision'
      // );
      // const lastApplicationItems = groupUniqueItems?.filter(
      //   (i) => i.application === lastApplicationDecision?.application
      // );
      // const lastApplicationElapsedTime = lastApplicationItems?.reduce(
      //   (acc, item) => {
      //     return acc + (item.elapsed_time / 1000 || 0);
      //   },
      //   0
      // );

      // Return the group stats json object
      return {
        group: groupName,
        size: groupSize,

        correct: correct?.length,
        accuracy: {
          value: isNaN(accuracy) ? 0 : accuracy,
          label: numeral(accuracy).format('0.0%'),
        },
        completed: completed?.length,
        wip: wip,
        inventory: inventory,

        collaboration: collaborationMode,
        task_quality: {
          value: isNaN(task_quality) ? 0 : task_quality,
          label:
            numeral(task_quality).format('0.0%') +
            ' (' +
            groupUniqueItems.length +
            ')',
        },

        // Details
        ...game_applications,

        // // Tasks
        // first_front_ratio: this.formatElapsed(firstFrontRatioElapsedTime),
        // last_front_ratio: this.formatElapsed(lastFrontRatioElapsedTime),
        // first_back_ratio: this.formatElapsed(firstBackRatioElapsedTime),
        // last_back_ratio: this.formatElapsed(lastBackRatioElapsedTime),
        // first_loan_to_value: this.formatElapsed(firstLoanToValueElapsedTime),
        // last_loan_to_value: this.formatElapsed(lastLoanToValueElapsedTime),
        // first_risk_assessment: this.formatElapsed(
        //   firstRiskAssessmentElapsedTime
        // ),
        // last_risk_assessment: this.formatElapsed(lastRiskAssessmentElapsedTime),
        // first_decision: this.formatElapsed(firstDecisionElapsedTime),
        // last_decision: this.formatElapsed(lastDecisionElapsedTime),

        // // Initial Application and Last Application
        // first_application: this.formatElapsed(firstApplicationElapsedTime),
        // last_application: this.formatElapsed(lastApplicationElapsedTime),

        // // Authors
        // first_front_ratio_author: this.getItemAuthor(firstFrontRatio)?.email,
        // last_front_ratio_author: this.getItemAuthor(lastFrontRatio)?.email,
        // first_back_ratio_author: this.getItemAuthor(firstBackRatio)?.email,
        // last_back_ratio_author: this.getItemAuthor(lastBackRatio)?.email,
        // first_loan_to_value_author: this.getItemAuthor(firstLoanToValue)?.email,
        // last_loan_to_value_author: this.getItemAuthor(lastLoanToValue)?.email,
        // first_risk_assessment_author:
        //   this.getItemAuthor(firstRiskAssessment)?.email,
        // last_risk_assessment_author:
        //   this.getItemAuthor(lastRiskAssessment)?.email,
        // first_decision_author: this.getItemAuthor(firstDecision)?.email,
        // last_decision_author: this.getItemAuthor(lastDecision)?.email,
      };
    });

    // Clone the groups list and return plain values
    const groupsListCSV = groupsList.map((g) => {
      const keys = Object.keys(g);
      const values = keys.map((k) => g[k]?.label || g[k]);
      // Return the object with plain values
      // use array reduce
      const plain_object = keys.reduce((acc, key, index) => {
        acc[key] = values[index];
        return acc;
      }, {});

      return plain_object;
    });

    // console.log({ playersList });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title fs-3'>
          Leaderboard
          <small>
            <CSVLink
              className={`btn btn-sm btn-link ms-3`}
              filename={`LoanProcessing_Leaderboard_${game?.data?.name}_${game?.data?.created_at}.csv`}
              headers={[
                { label: 'Group', key: 'group' },
                { label: 'Group Size', key: 'size' },
                { label: 'Correct', key: 'correct' },
                { label: 'Accuracy', key: 'accuracy' },
                { label: 'Completed', key: 'completed' },
                { label: 'WIP', key: 'wip' },
                { label: 'Inventory', key: 'inventory' },
                { label: 'Collaboration Mode', key: 'collaboration' },
                { label: 'Task Quality', key: 'task_quality' },

                // Details: Loop the 49 applications and list the activities
                ...Array.from(Array(49).keys())
                  .map((i) => {
                    const loan_number = i + 1;
                    return [
                      // Front Ratio
                      {
                        label: `Loan ${loan_number} Front Ratio`,
                        key: `loan_${loan_number}_front_ratio_type`,
                      },
                      {
                        label: `Loan ${loan_number} Front Ratio Author`,
                        key: `loan_${loan_number}_front_ratio_author`,
                      },
                      {
                        label: `Loan ${loan_number} Front Ratio Created At`,
                        key: `loan_${loan_number}_front_ratio_created_at`,
                      },
                      {
                        label: `Loan ${loan_number} Front Ratio Elapsed Time`,
                        key: `loan_${loan_number}_front_ratio_elapsed_time`,
                      },
                      {
                        label: `Loan ${loan_number} Front Ratio Performance`,
                        key: `loan_${loan_number}_front_ratio_performance`,
                      },
                      // Back Ratio
                      {
                        label: `Loan ${loan_number} Back Ratio`,
                        key: `loan_${loan_number}_back_ratio_type`,
                      },
                      {
                        label: `Loan ${loan_number} Back Ratio Author`,
                        key: `loan_${loan_number}_back_ratio_author`,
                      },
                      {
                        label: `Loan ${loan_number} Back Ratio Created At`,
                        key: `loan_${loan_number}_back_ratio_created_at`,
                      },
                      {
                        label: `Loan ${loan_number} Back Ratio Elapsed Time`,
                        key: `loan_${loan_number}_back_ratio_elapsed_time`,
                      },
                      {
                        label: `Loan ${loan_number} Back Ratio Performance`,
                        key: `loan_${loan_number}_back_ratio_performance`,
                      },
                      // Loan-To-Value
                      {
                        label: `Loan ${loan_number} Loan-To-Value`,
                        key: `loan_${loan_number}_loan_to_value_type`,
                      },
                      {
                        label: `Loan ${loan_number} Loan-To-Value Author`,
                        key: `loan_${loan_number}_loan_to_value_author`,
                      },
                      {
                        label: `Loan ${loan_number} Loan-To-Value Created At`,
                        key: `loan_${loan_number}_loan_to_value_created_at`,
                      },
                      {
                        label: `Loan ${loan_number} Loan-To-Value Elapsed Time`,
                        key: `loan_${loan_number}_loan_to_value_elapsed_time`,
                      },
                      {
                        label: `Loan ${loan_number} Loan-To-Value Performance`,
                        key: `loan_${loan_number}_loan_to_value_performance`,
                      },
                      // Risk Assessment
                      {
                        label: `Loan ${loan_number} Risk Assessment`,
                        key: `loan_${loan_number}_risk_assessment_type`,
                      },
                      {
                        label: `Loan ${loan_number} Risk Assessment Author`,
                        key: `loan_${loan_number}_risk_assessment_author`,
                      },
                      {
                        label: `Loan ${loan_number} Risk Assessment Created At`,
                        key: `loan_${loan_number}_risk_assessment_created_at`,
                      },
                      {
                        label: `Loan ${loan_number} Risk Assessment Elapsed Time`,
                        key: `loan_${loan_number}_risk_assessment_elapsed_time`,
                      },
                      {
                        label: `Loan ${loan_number} Risk Assessment Performance`,
                        key: `loan_${loan_number}_risk_assessment_performance`,
                      },
                      // Decision
                      {
                        label: `Loan ${loan_number} Decision`,
                        key: `loan_${loan_number}_decision_type`,
                      },
                      {
                        label: `Loan ${loan_number} Decision Author`,
                        key: `loan_${loan_number}_decision_author`,
                      },
                      {
                        label: `Loan ${loan_number} Decision Created At`,
                        key: `loan_${loan_number}_decision_created_at`,
                      },
                      {
                        label: `Loan ${loan_number} Decision Elapsed Time`,
                        key: `loan_${loan_number}_decision_elapsed_time`,
                      },
                      {
                        label: `Loan ${loan_number} Decision Performance`,
                        key: `loan_${loan_number}_decision_performance`,
                      },
                      // Total elapsed time for the application
                      {
                        label: `Loan ${loan_number} Elapsed Total`,
                        key: `loan_${loan_number}_elapsed_total`,
                      },
                      // First item (response) time
                      {
                        label: `Loan ${loan_number} Started At`,
                        key: `loan_${loan_number}_first_item_created_at`,
                      },
                      // Last item (response) time
                      {
                        label: `Loan ${loan_number} Completed At`,
                        key: `loan_${loan_number}_last_item_created_at`,
                      },
                    ];
                  })
                  .flat(),

                // { label: 'Loan 1 Activity', key: 'loan_1_activity' },
                // { label: 'Loan 1 Author', key: 'loan_1_author' },
                // { label: 'Loan 1 Created At', key: 'loan_1_created_at' },
                // { label: 'Loan 1 Elapsed Time', key: 'loan_1_elapsed_time' },
                // { label: 'Loan 1 Performance', key: 'loan_1_performance' },

                // // Details
                // { label: 'First Front Ratio', key: 'first_front_ratio' },
                // {
                //   label: 'First Front Ratio Author',
                //   key: 'first_front_ratio_author',
                // },
                // { label: 'Last Front Ratio', key: 'last_front_ratio' },
                // {
                //   label: 'Last Front Ratio Author',
                //   key: 'last_front_ratio_author',
                // },
                // { label: 'First Back Ratio', key: 'first_back_ratio' },
                // {
                //   label: 'First Back Ratio Author',
                //   key: 'first_back_ratio_author',
                // },
                // { label: 'Last Back Ratio', key: 'last_back_ratio' },
                // {
                //   label: 'Last Back Ratio Author',
                //   key: 'last_back_ratio_author',
                // },
                // { label: 'First Loan-To-Value', key: 'first_loan_to_value' },
                // {
                //   label: 'First Loan-To-Value Author',
                //   key: 'first_loan_to_value_author',
                // },
                // { label: 'Last Loan-To-Value', key: 'last_loan_to_value' },
                // {
                //   label: 'Last Loan-To-Value Author',
                //   key: 'last_loan_to_value_author',
                // },
                // {
                //   label: 'First Risk Assessment',
                //   key: 'first_risk_assessment',
                // },
                // {
                //   label: 'First Risk Assessment Author',
                //   key: 'first_risk_assessment_author',
                // },
                // { label: 'Last Risk Assessment', key: 'last_risk_assessment' },
                // {
                //   label: 'Last Risk Assessment Author',
                //   key: 'last_risk_assessment_author',
                // },
                // { label: 'First Decision', key: 'first_decision' },
                // {
                //   label: 'First Decision Author',
                //   key: 'first_decision_author',
                // },
                // { label: 'Last Decision', key: 'last_decision' },
                // { label: 'Last Decision Author', key: 'last_decision_author' },

                // {
                //   label: 'First Completed Application Cumulative Time',
                //   key: 'first_application',
                // },
                // {
                //   label: 'Last Completed Application Cumulative Time',
                //   key: 'last_application',
                // },
              ]}
              data={groupsListCSV}>
              Export data
            </CSVLink>
          </small>
          <small className='float-end'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3' style={{ fontSize: '14px' }}>
          <List
            labels={[
              'Group',
              'Correct',

              'Accuracy',
              'Completed',
              'WIP',
              'Inventory',

              'Group Size',
              'Collaboration (Mode)',
              'Task Quality',

              // Tasks
              // 'First Front Ratio',
              // 'First Front Ratio Author',

              // 'Last Front Ratio',
              // 'Last Front Ratio Author',

              // 'First Back Ratio',
              // 'First Back Ratio Author',

              // 'Last Back Ratio',
              // 'Last Back Ratio Author',

              // 'First Loan-To-Value',
              // 'First Loan-To-Value Author',

              // 'Last Loan-To-Value',
              // 'Last Loan-To-Value Author',

              // 'First Risk Assessment',
              // 'First Risk Assessment Author',

              // 'Last Risk Assessment',
              // 'Last Risk Assessment Author',

              // 'First Decision',
              // 'First Decision Author',

              // 'Last Decision',
              // 'Last Decision Author',

              // <span>
              //   Precision{' '}
              //   <FontAwesomeIcon
              //     id='tip'
              //     icon='question-circle'
              //     size='1x'
              //     className='icon text-dark'
              //   />
              // </span>,
            ]}
            fields={[
              'group',
              'correct',

              'accuracy',
              'completed',
              'wip',
              'inventory',

              'size',
              'collaboration',
              'task_quality',

              // Tasks
              // 'first_front_ratio',
              // 'first_front_ratio_author',

              // 'last_front_ratio',
              // 'last_front_ratio_author',

              // 'first_back_ratio',
              // 'first_back_ratio_author',

              // 'last_back_ratio',
              // 'last_back_ratio_author',

              // 'first_loan_to_value',
              // 'first_loan_to_value_author',

              // 'last_loan_to_value',
              // 'last_loan_to_value_author',

              // 'first_risk_assessment',
              // 'first_risk_assessment_author',

              // 'last_risk_assessment',
              // 'last_risk_assessment_author',

              // 'first_decision',
              // 'first_decision_author',

              // 'last_decision',
              // 'last_decision_author',
            ]}
            sortables={[
              'group',
              'correct',

              'accuracy',
              'completed',
              'wip',
              'inventory',

              'size',
              'collaboration',
              'task_quality',
            ]}
            sort={1}
            sortedBy={3}
            data={groupsList}
            onRowClick={this.rowClick}
          />
          {/* <Tooltip isOpen={this.state.tip} target='tip' toggle={this.toggleTip}>
            Precision is defined as the number of correct responses divided by
            the total number of responses submitted.
          </Tooltip> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
