import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class DebriefFlowRate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      selected_group: '',
    };

    // Bindings
    this.handleFilter = this.handleFilter.bind(this);
    this.handleSetNextFilter = this.handleSetNextFilter.bind(this);
    this.handleSetPreviousFilter = this.handleSetPreviousFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  // Sets the selected group or null if the group is "All Groups" (empty value)
  handleFilter(e) {
    const { value } = e.target;
    const selected_group = value.length > 0 ? value : null;
    this.setState({ selected_group });
  }

  // Sets the filter to the next option in the list
  handleSetNextFilter(e) {
    const { game } = this.props;
    const { selected_group } = this.state;
    const gameData = game.data;
    const groups = gameData?.groups;

    // If no group is selected, select the first group
    if (!selected_group) {
      this.setState({ selected_group: groups[0]._id });
      return;
    }

    // If the selected group is the last group, select the first option in the list
    const lastGroup = groups[groups.length - 1];
    if (selected_group === lastGroup._id) {
      this.setState({ selected_group: '' });
      return;
    }

    // Select the next group in the list
    const selectedGroupIndex = groups.findIndex(
      (g) => g._id === selected_group
    );
    const nextGroup = groups[selectedGroupIndex + 1];
    this.setState({ selected_group: nextGroup._id });
  }

  // Sets the filter to the previous option in the list
  handleSetPreviousFilter(e) {
    const { game } = this.props;
    const { selected_group } = this.state;
    const gameData = game.data;
    const groups = gameData?.groups;

    // If no group is selected, select the last group
    if (!selected_group) {
      this.setState({ selected_group: groups[groups.length - 1]._id });
      return;
    }

    // If the selected group is the first element in the list, select the last option in the list
    const firstGroup = groups[0];
    if (selected_group === firstGroup._id) {
      this.setState({ selected_group: '' });
      return;
    }

    // Select the previous group in the list
    const selectedGroupIndex = groups.findIndex(
      (g) => g._id === selected_group
    );
    const previousGroup = groups[selectedGroupIndex - 1];
    this.setState({ selected_group: previousGroup._id });
  }

  render() {
    const { game } = this.props;
    const { selected_group } = this.state;
    const gameData = game.data;

    // Select the groups to be used in the chart
    const selectedGroups =
      selected_group?.length > 0
        ? // Filter the groups by the selected group
          gameData?.groups.filter((g) => g._id === selected_group)
        : // If no group is selected, use all the groups
          gameData?.groups;

    // Filter activities by activity type. Only return 'front-ratio' and 'decision'
    // store the filtered items in a flat array
    const filteredItems = selectedGroups
      ?.map((g) => {
        // Get the unique applications in the group
        const uniqueApplications = [
          ...new Set(g?.items?.map((i) => i.application)),
        ];

        const firstsAndDecisions = uniqueApplications
          ?.map((a) => {
            // Get & Sort the groupApplicationItems by created_at from oldest to newest
            const groupApplicationItems = g?.items
              ?.filter((i) => i.application === a)
              .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

            // Get the decision for the application
            const hasDecision = groupApplicationItems?.find(
              (i) => i.application === a && i.activity === 'decision'
            );

            // Get the first item for the application that is not a decision
            const firstItem = groupApplicationItems?.find(
              (i) => i.application === a && i.activity !== 'decision'
            );

            return [firstItem, hasDecision];
          })
          .flat()
          .filter((i) => i !== null && i !== undefined);

        console.log({ firstsAndDecisions });

        return firstsAndDecisions;
      })
      .flat();

    console.log({ filteredItems });

    // // Sort the filtered items by created_at
    // const sortedFilteredItems = filteredItems.sort(
    //   (a, b) => new Date(a.created_at) - new Date(b.created_at)
    // );

    // Loop every group and return the items
    // Store each group's items in an flat array
    const groupsSubmittedItems = filteredItems.filter(
      (i) => i.activity === 'decision'
    );

    // Loop every group and return the items (applications) with activity 'front-ratio'
    // and do not include the items with activity 'decision'
    const groupsInitializedItems = filteredItems.filter((i) => {
      // Check if the item application is also in the decisions
      // const foundInSubmittedItems = groupsSubmittedItems.find(
      //   (si) => si.application === i.application
      // );
      return i.activity !== 'decision';
      // return i.activity === 'front-ratio' && !foundInSubmittedItems;
    });

    // Create an array of length timeSteps
    // Each item in the array is a moment in time starting from the firstSubmittedItem created_at
    // to the lastSubmittedItem created_at
    const initializedBeforeStep = [];
    const submittedBeforeStep = [];
    const inventorySerie = [];
    const wipSerie = [];
    const filteredSortedItems = filteredItems.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
    const timeStepsArray = filteredSortedItems.map((timeEvent, i) => {
      // Let's calculate the time step using the created_at date
      const timeStepInDate = new Date(timeEvent.created_at);

      // Calculate how many items were initialized before this time step
      const initializedBefore = groupsInitializedItems.filter((item) => {
        return new Date(item.created_at) <= timeStepInDate;
      });
      initializedBeforeStep.push(initializedBefore?.length);

      // Calculate how many items were submitted before this time step
      const submittedBefore = groupsSubmittedItems.filter((item) => {
        return new Date(item.created_at) <= timeStepInDate;
      });
      submittedBeforeStep.push(submittedBefore?.length);

      // Calculate how many items are still in progress
      // This is done by counting how many items were initialized before this time step
      // and after the previous time step
      // const workInProgressTotal = groupsInitializedItems.filter((item) => {
      //   if (i > 0) {
      //     // Check if there's a previous time event
      //     const prevTimeEvent = sortedFilteredItems[i - 1];
      //     const prevtimeStepInDate = new Date(prevTimeEvent.created_at);
      //     return (
      //       new Date(item.created_at) <= timeStepInDate &&
      //       new Date(item.created_at) > prevtimeStepInDate
      //     );
      //   }

      //   return new Date(item.created_at) <= timeStepInDate;
      // });

      // Calculate the WIP by substrating the submitted items from the initialized items
      const workInProgressTotal =
        initializedBefore?.length - submittedBefore?.length;
      wipSerie.push(workInProgressTotal);

      // Calculate the inventory level
      const totalGroups = gameData?.groups?.length || 0;
      const inventoryLevel = 49 * totalGroups - initializedBefore?.length;
      inventorySerie.push(inventoryLevel);

      // return timeStepInDate;
      return `T${i + 1}`;
    });

    // console.log({ initializedBeforeStep, submittedBeforeStep, timeStepsArray });

    // Loop the sorted items and calculate the flow rate
    // const groupsFlowRate = groupsSubmittedItems.map((item, i) => {
    //   return [new Date(i?.created_at), i + 1];
    // });

    // // Create the x-axis categories using the dates
    // const xAxisCategories = groupsInitializedItems.map((item) => {
    //   return new Date(item.created_at);
    // });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title fs-3'>
          Flow Rate
          <small class='float-end'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3'>
          <p className='text-center'>
            Inflow represents applications that have begun review, while outflow
            captures submitted applications Use the toggle to display the
            inventory level over time. Inflow and outflow are measured every
            time a task is completed or an application decision is logged, and
            both events add a new T# on the x-axis.
          </p>

          {/* Toggle groups component */}
          <div className='d-flex justify-content-center align-items-center bg-whitxe mb-2'>
            {/* Create a select input that contains a list of the session groups
            On selection of an item set it as the selected group
            Include "All Groups" as the first option in the list */}
            {/* <label className='me-3'>Filter by Group:</label> */}

            <div className='input-group w-25'>
              <button
                className='btn btn-sm btn-outline-secondary'
                onClick={this.handleSetPreviousFilter}>
                &#8592;
              </button>
              <select
                className='form-select form-select-sm text-center border-secondary'
                style={{ minWidth: '200px' }}
                onChange={this.handleFilter}
                value={selected_group}>
                <option value=''>All Groups</option>
                {gameData?.groups?.map((g, i) => {
                  const groupName =
                    g.name.length > 0 ? g.name : `Group ${i + 1}`;
                  return (
                    <option key={`group-${i}`} value={g._id}>
                      {groupName}
                    </option>
                  );
                })}
              </select>
              <button
                className='btn btn-sm btn-outline-secondary'
                onClick={this.handleSetNextFilter}>
                &#8594;
              </button>
            </div>
          </div>

          <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: { text: '' },
              chart: { type: 'line', height: '500px' },
              credits: { enabled: false },
              legend: { enabled: true },
              xAxis: {
                categories: timeStepsArray,
              },
              yAxis: [
                {
                  labels: { style: { fontSize: '14px' } },
                  allowDecimals: false,
                  min: 0,
                  title: {
                    text: '',
                  },
                },
                {
                  labels: { style: { fontSize: '14px' } },
                  allowDecimals: false,
                  min: 0,
                  title: {
                    text: '',
                  },
                  opposite: true,
                },
              ],
              series: [
                {
                  name: 'Inflow',
                  // color: 'rgba(223, 83, 83, .5)',
                  data: initializedBeforeStep,
                },
                {
                  name: 'Outflow',
                  // color: 'rgba(80,180,50, .5)',
                  data: submittedBeforeStep,
                },
                {
                  name: 'WIP',
                  type: 'column',
                  color: 'rgba(80,180,50, .25)',
                  data: wipSerie,
                  // yAxis: 1,
                  visible: false,
                },
              ],
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(DebriefFlowRate);
