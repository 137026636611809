import React from 'react';
import { connect } from 'react-redux';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faArrowCircleUp,
  faArrowCircleDown,
} from '@fortawesome/free-solid-svg-icons';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: this.props.labels || [],
      fields: this.props.fields || [],
      sortables: this.props.sortables || [],
      data: this.props.data || [],
      sort: this.props.sort || -1,
      sortedBy: this.props.sortedBy || null,
    };

    // Bindings
    this.sortTable = this.sortTable.bind(this);
  }

  // On mount, sort the table by the sortedBy field if it exists
  componentDidMount() {
    if (
      typeof this.state.sortedBy !== 'undefined' &&
      this.state.sortedBy !== null
    )
      this.sortTable(this.state.sortedBy);
  }

  componentWillReceiveProps(newProps) {
    this.setState(
      {
        data: newProps.data,
      },
      // If the sortedBy field changes, sort the table by the new field
      () => {
        if (
          typeof this.state.sortedBy !== 'undefined' &&
          this.state.sortedBy !== null
        )
          this.sortTable(this.state.sortedBy);
      }
    );
  }

  sortTable(col) {
    const sort = this.state.sort;
    const field = this.state.fields[col];
    const sortFunction = (a, b) => {
      const aVal =
        typeof a[field].value !== 'undefined' ? a[field].value : a[field];
      const bVal =
        typeof b[field].value !== 'undefined' ? b[field].value : b[field];
      if (aVal < bVal) return -1 * sort;
      if (aVal > bVal) return 1 * sort;
      return 0;
    };
    let toBeSortedItems = [...this.state.data];
    let numericItems = toBeSortedItems.filter((i) =>
      typeof i[field].value !== 'undefined'
        ? !isNaN(i[field].value)
        : !isNaN(i[field])
    );
    let alphaItems = toBeSortedItems.filter((i) =>
      typeof i[field].value !== 'undefined'
        ? isNaN(i[field].value)
        : isNaN(i[field])
    );
    numericItems.sort(sortFunction);
    alphaItems.sort(sortFunction);
    const sortedItems = numericItems.concat(alphaItems);
    this.setState({
      data: sortedItems,
      sort: this.state.sort * -1,
      sortedBy: col,
    });
  }

  onRowClick(data, index) {
    if (this.props.onRowClick) this.props.onRowClick(data, index);
  }

  render() {
    const keys = this.state.fields;
    const headings = this.state.labels.map((k, ki) => {
      const active = this.state.sortedBy === ki ? 'active' : '';
      const arrow =
        this.state.sort === -1 ? faArrowCircleUp : faArrowCircleDown;
      const sortable =
        this.state.sortables.indexOf(keys[ki]) > -1 ? true : false;

      return sortable ? (
        <th
          key={'th-' + ki}
          onClick={() => this.sortTable(ki)}
          className={'text-center ' + active}
          style={{ cursor: 'pointer' }}>
          {k}{' '}
          {active && <FontAwesomeIcon icon={arrow} className='text-primary' />}
          {!active && (
            <FontAwesomeIcon
              icon={faSort}
              className='text-muted'
              style={{ opacity: 0.5 }}
            />
          )}
        </th>
      ) : (
        <th key={'th-' + ki} className='text-center'>
          {k}
        </th>
      );
    });
    const rows = this.state.data.map((d, di) => {
      // Custom className for the row
      const className = d?.className ? d.className : '';

      let cols = keys.map((k, ki) => {
        if (typeof d[k] === 'undefined')
          return console.log('Key error: ', k, d);
        const value = d[k].value ? d[k].value : d[k];
        const label = d[k].label ? d[k].label : value;
        return (
          <td
            onClick={() => this.onRowClick(d, di)}
            key={'td-' + di + '-' + ki}
            className={`text-center align-middle ${className}`}>
            {label}
          </td>
        );
      });
      return <tr key={'tr-' + di}>{cols}</tr>;
    });

    return (
      <table className='table table-hover'>
        <thead className=''>
          <tr>{headings}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(List);
