import React from 'react';

const GroupCard = ({ group, index = 0 }) => {
  // Remove duplicate items from the group
  // use the type and application properties to identify the items
  // in case of duplicates, keep the most recent value for created_at property
  const groupUniqueItems = group?.items?.reduce((acc, item) => {
    const { activity, application } = item;
    const existingItem = acc.find(
      (i) => i.activity === activity && i.application === application
    );
    if (existingItem) {
      // If the item already exists, check if the current item is more recent
      if (new Date(item.created_at) > new Date(existingItem.created_at)) {
        // If the current item is more recent, replace the existing item
        acc.splice(acc.indexOf(existingItem), 1, item);
      }
    } else {
      // If the item doesn't exist, add it to the accumulator
      acc.push(item);
    }
    return acc;
  }, []);

  // Count the number of processed applications per group
  const processedItems = groupUniqueItems?.filter(
    (i) => i?.activity === 'decision'
  );
  // Count the number of correctly processed applications per group
  const correctItems = processedItems?.filter((i) => i?.performance === 100);

  // Calculate stats for the group
  const uniqueApplications = [
    ...new Set(group?.items?.map((i) => i.application)),
  ];

  // Count the number of items that were reworked
  // An item is "reworked" if there is another item (different than the current)
  // with the same activity and application
  const reworkedItems = group?.items?.filter((i) => {
    const { activity, application } = i;
    const multi = group?.items?.find(
      (j) =>
        j?.activity === activity &&
        j?.application === application &&
        j?._id !== i?._id &&
        // Validate the created_at property to make sure we're not comparing the same items twice
        new Date(j?.created_at) > new Date(i?.created_at)
    );
    return multi ? true : false;
  });

  // Count how many players worked on the same item
  // NOTE: If we use groupUniqueItems, then we're removing the re-worked items
  const collaborationItems = uniqueApplications?.map((i) => {
    const workers = group?.items
      ?.filter((j) => j?.application === i && j?._id !== i?._id)
      .map((j) => j?.author);
    const uniqueAuthors = [...new Set(workers)];
    return uniqueAuthors ? uniqueAuthors?.length : 0;
  });
  // Let's calculate the statistics mode for the collaboration items
  const collaborationModeMap = collaborationItems?.reduce((acc, item) => {
    if (item) {
      acc[item] = typeof acc[item] !== 'undefined' ? acc[item] + 1 : 1;
    }
    return acc;
  }, {});
  const collaborationMode = Object.keys(collaborationModeMap).reduce(
    (a, b) => (collaborationModeMap[a] > collaborationModeMap[b] ? a : b),
    []
  );

  // console.log({
  //   collaborationItems,
  //   collaborationModeMap,
  //   collaborationMode,
  // });

  // TODO (nocode): Review the previous calculations and make sure to use only the latest item for every activity
  // TODO (nocode): Review with Renzo the criteria for "collaboration" and "rework"

  return (
    <div className='card mb-3'>
      <div className='card-body'>
        <div className='row small'>
          {/* LEFT PANEL */}
          <div className='col-4'>
            <h6>{group?.name || `Group ${index + 1}`}</h6>
            {group?.players?.map((player) => {
              // The author initials or email address
              const theAuthor = player;

              let authorNameInitials = theAuthor?.name ? theAuthor?.name : '';
              // authorNameInitials += ' ';
              // authorNameInitials += theAuthor?.lastname
              //   ? theAuthor?.lastname
              //   : '';
              authorNameInitials = authorNameInitials.trim();

              const authorEmailInitials = theAuthor?.email
                ? (theAuthor?.email[0] + theAuthor?.email[1])
                    .trim()
                    .toUpperCase()
                : '';
              const authorInitials = authorNameInitials || authorEmailInitials;
              return (
                <div key={`player-${player?._id}`}>
                  <span>{authorInitials || 'Player'}</span>
                </div>
              );
            })}
          </div>

          {/* RIGHT PANEL */}
          <div className='col-8'>
            <div className='row'>
              <div className='col-6 text-center'>
                <b>Loans Processed:</b>
                <br />
                {processedItems?.length || 0}
              </div>
              <div className='col-6 text-center'>
                <b>Collaboration (Mode):</b>
                <br />
                {collaborationMode || 0}
              </div>
              <div className='col-6 text-center mt-3'>
                <b>Correctly Processed:</b>
                <br />
                {correctItems?.length || 0}
              </div>
              <div className='col-6 text-center mt-3'>
                <b>Rework:</b>
                <br />
                {reworkedItems?.length || 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupCard;
