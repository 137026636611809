import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class AddPlayerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastname: '',
      email: '',
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    let state = { ...this.state };

    // Set the field value
    state[n] = v;

    // Lowercase emails
    if (n === 'email') state[n] = v.toLowerCase();
    // Parse group as int
    if (n === 'group') {
      state[n] = parseInt(v);
      if (isNaN(state[n])) state[n] = '';
    }

    this.setState(state, () => {
      this.props.onChange && this.props.onChange(state);
    });
  }

  render() {
    // Get the groups
    const groups = this.props.groups || [];

    return (
      <div className='form-row mb-3'>
        <div className='col'>
          <input
            name='name'
            className='form-control mb-3'
            value={this.state.name}
            placeholder='Name'
            onChange={this.handleChange}
          />
        </div>
        <div className='col'>
          <input
            name='lastname'
            className='form-control mb-3'
            value={this.state.lastname}
            placeholder='Surname'
            onChange={this.handleChange}
          />
        </div>
        <div className='col'>
          <input
            name='email'
            className='form-control mb-3'
            value={this.state.email}
            placeholder='Email'
            onChange={this.handleChange}
          />
        </div>
        <div className='col'>
          {/* <input
            name='group'
            type='number'
            min={1}
            className='form-control'
            value={this.state.group}
            placeholder='Group #'
            onChange={this.handleChange}
          /> */}
          <select
            name='group'
            className='form-control'
            value={this.state.group}
            onChange={this.handleChange}>
            {/* <option value=''>Select a group</option> */}
            <option value={0}>Create a new group</option>
            {groups.map((g, gi) => (
              <option value={gi + 1}>Group {gi + 1}</option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default AddPlayerForm;
