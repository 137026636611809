import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

// Images
// import image1 from '../../assets/function-1.png';
// import image2 from '../../assets/function-2.png';
// import image3 from '../../assets/function-3.png';

class Debrief extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { game } = this.props;

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Overview{' '}
          {/* <FontAwesomeIcon
            className='ms-1 text-secondary'
            style={{ fontSize: '1.25rem', cursor: 'pointer' }}
            icon='question-circle'
            onClick={() => this.setState({ popup: !popup })}
          /> */}{' '}
          <small className='float-end'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row mt-5'>
          <div className='col-12 mb-5'>
            <p className='text-center'>
              Review the available analysis and determine which ones you would
              like to display:
            </p>
          </div>

          <div className='col text-center'>
            <h5 className='mb-3'>Accuracy</h5>
            <p className='text-center px-5'>
              Scatterplot of assessed applications versus decision accuracy.
            </p>
            <p>Focus: Team</p>
          </div>
          <div className='col text-center'>
            <h5 className='mb-3'>Progress</h5>
            <p className='text-center px-5'>
              Bar chart of applications by status across teams.
            </p>
            <p>Focus: Team</p>
          </div>
          <div className='col text-center'>
            <h5 className='mb-3'>Flow Rate</h5>
            <p className='text-center px-5'>
              Line chart of inflow versus outflow as well as inventory over
              time.
            </p>
            <p>Focus: Team</p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(Debrief);
